import "twin.macro";
import AboutImg from "../images/AboutUs/about-img.png";
import AboutBack from "../images/AboutUs/back.png";
import retailShop from "../images/AboutUs/retailShop.webp";
import silks from "../images/AboutUs/silks.jpg";
import members from "../images/AboutUs/members-banner.jpg";
import { memo } from "react";

function AboutUs({ branch }) {
  return (
    <>
      <div className="container" tw="lg:py-16 py-10 ">
        <div className="row align-items-center flex-column-reverse flex-lg-row py-2">
          <div className="col-md-12 col-lg-6">
            <h1 tw="text-5xl my-5 lg:mt-2">WHO WE ARE!!</h1>
            <div className="ptSans text-base">
              <p tw="text-zinc-300 ">
                {branch.storeName}   is one of the most trusted jewellery brand in and around Namakkal
              </p>
              <p tw="text-zinc-300 ">
              {branch.storeName}  is one of the most trusted jewellery brand in and around Namakkal
              </p>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 ">
            <img
              src={AboutBack}
              alt="About-Img"
              tw="absolute right-0 top-0 -z-10 hidden lg:block w-5/12"
            />
            <img
              src={AboutImg}
              alt="About-Img"
              className="img-fluid m-auto"
              tw="transform transition duration-500 hover:scale-110"
            />
          </div>
        </div>

      
      </div>
    </>
  );
}
export default memo(AboutUs);
