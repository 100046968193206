import tw from "twin.macro";
import Carousel from "./components/Carousel.js";
import ProductCategory from "./components/ProductCategory.js";
import GenderBased from "./components/GenderBasedProduct.js";
import ProductType from "./components/ProductType";
import ArrivalsLiked from "components/ArrivalsLiked.js";
// import SocialMedia from "components/SocialMedia.js";

const Container = tw.div`relative font-display overflow-hidden`;
export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */



  return (
    <Container>
      <Carousel />
      <ProductType />
      {/* <ProductCategory /> */}
      {/* <ArrivalsLiked /> */}
      {/* <GenderBased /> */}
      {/* <SocialMedia /> */}
    </Container>
  );
};
