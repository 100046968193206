import tw from "twin.macro";
import styled from "styled-components";
import { schemeType } from "../api/NewSchemeApi";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg/svg-decorator-blob-7.svg";
import Noimg from "../images/no_img.jpg";
import icon1 from "images/SavingScheme/1.png";
import icon2 from "images/SavingScheme/2.png";
import icon3 from "images/SavingScheme/3.png";
import { dataList } from "api/SkeletonData";
import Skeleton from "react-loading-skeleton";
import { getCustomerId } from "utility/HeaderConfig";
import { useCartContext } from "context/UseCartContext";

const Container = tw.div`relative font-display overflow-hidden`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-custom-100`}
`;

export default ({ branch }) => {
  const [Scheme, setScheme] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [banner, setBanner] = useState([]);
  const [isReadMore, setIsReadMore] = useState(false);

  const { dispatch } = useCartContext();

  useEffect(() => {
    getSchemeType();
    // getBanner();
    // eslint-disable-next-line
  }, []);

  // const getBanner = () => {
  //   configImages &&
  //     configImages.E_COMMERCE_BANNERS.forEach((data) => {
  //       if (data.key === "savingScheme") {
  //         setBanner(data.value);
  //       }
  //     });
  // };

  const history = useHistory();

  const getSchemeType = () => {
    setLoading(true);
    let filter = {
      active: 0,
    };
    schemeType(filter).then(
      (data) => {
        setScheme(data.response.content);
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const toggleReadMore = (id) => {
    setIsReadMore(id);
  };

  const checkLogin = () => {
    if (getCustomerId()) {
      history.push("/MyScheme");
    } else {
      dispatch({ type: "MODAL", payload: true });
    }
  };

  return (
    <Container>
      <section tw="pb-10 relative" className="custom_background">
        <div className="w-100 text-white " tw="bg-custom-100">
          <div className="container py-4">
            <h2 className="text-center text-uppercase">
              Benefits of{" "}
              <span className="text-warning">{branch.storeName}</span> Saving
              Scheme
            </h2>
            <div className="row py-4">
            <div className="col-md-6 col-xl-4">
                <img
                  src={icon1}
                  alt="Jewellery Saving Scheme"
                  className="m-auto py-3 spinner"
                />
                <h4 className="text-center" tw="text-xl">
                  Easy Future
                </h4>
                <p className="text-center" tw="text-sm">
                  Boon to middle class people. Special help to all class people.
                </p>
              </div>
              <div className="col-md-6 col-xl-4 position-relative">
                {/* <img
                  src={icon3}
                  alt="Zinger Jewellers saving scheme"
                  className="m-auto py-3 spinner"
                />
                <h4 className="text-center" tw="text-xl">
                  Your Money is Safe
                </h4>
                <p className="text-center" tw="text-sm">
                  The money which is saved guaranteed for Ornament Purchase.
                </p> */}
                <div tw="p-5 bg-white	absolute rounded-xl border border-dotted border-custom-200 shadow-2xl">
                  <img
                    src={icon3}
                    alt="Zinger Jewellers saving scheme"
                    className="m-auto py-3 spinner"
                  />
                  <h4
                    className="text-center text-black"
                    tw="text-xl font-bold"
                  >
                   Special Schemes
                  </h4>
                 
                  <a
                    href="#join"
                    tw="rounded-lg px-3 py-2 w-full bg-custom-100 uppercase my-4 text-white text-center hover:text-white no-underline inline-block"
                  >
                    JOIN NEW SavingScheme at Shop
                  </a>
                 
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <img
                  src={icon2}
                  alt="Zinger Jewellers saving scheme"
                  className="m-auto py-3 spinner"
                />
                <h4 className="text-center" tw="text-xl">
                Your Money is Safe
                </h4>
                <p className="text-center" tw="text-sm">
                  The savings gold amount fetch admirable bonus, which is more
                  valuable than special discount.
                </p>
              </div>
            </div>
          </div>
        </div>
        
      </section>
    </Container>
  );
};
